import { graphql, PageProps } from "gatsby";
import React from "react";
import "twin.macro";
import { SEO } from "../components";
import { BlankPageNoImage } from "../components/common";
import { IStrapiPage } from "../utils/types";

const Cookies: React.FC<PageProps<{ strapiCookiesPolicy: IStrapiPage }>> = ({
  data: {
    strapiCookiesPolicy: {
      title,
      content: {
        data: { content },
      },
    },
  },
}) => {
  return (
    <>
      <SEO title="Cookies" />
      <BlankPageNoImage header={title} ariaLabel={title}>
        <div
          tw="prose font-light mx-auto"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </BlankPageNoImage>
    </>
  );
};

export default Cookies;

export const query = graphql`
  query cookiespagequery {
    strapiCookiesPolicy {
      content {
        data {
          content
        }
      }
      title
    }
  }
`;
